import React, { useState } from "react";

// Component for Contact Us page
const Contact_us = () => {
  // State to track the selected country
  const [selectedCountry, setSelectedCountry] = useState("Turky");

  // Event handler for country selection change
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  // Define the maps, phone numbers, and emails for each country
  const countryData = {
    Turky: {
      mapSrc: `https://maps.google.com/maps?width=100%&height=600&hl=en&q=${encodeURIComponent(
        "Bozkurt mahallesi ergenekon 41/103 , APT 41, Istanbul , Turkey"
      )}&ie=UTF8&t=&z=14&iwloc=B&output=embed`,
      address: "Bozkurt mahallesi ergenekon 41/103 , APT 41, Istanbul , Turkey",
      phone: "+44 7769 475900",
      email: "annu@malaktel.com",
    },
    Iraq: {
      mapSrc: `https://maps.google.com/maps?width=100%&height=600&hl=en&q=${encodeURIComponent(
        "Zayouna is a neighbourhood of east Baghdad, Iraq"
      )}&ie=UTF8&t=&z=14&iwloc=B&output=embed`,
      address: "Zayouna is a neighbourhood of east Baghdad, Iraq",
      phone: "+962 7 9777 9777",
      email: "wissam@malaktel.com",
    },
    Egypt: {
      mapSrc: `https://maps.google.com/maps?width=100%&height=600&hl=en&q=${encodeURIComponent(
        "cairo mohi eldin street building, egypt "
      )}&ie=UTF8&t=&z=14&iwloc=B&output=embed`,
      address: "cairo mohi eldin street building, egypt",
      phone: "+962 7 9777 9777",
      email: "wissam@malaktel.com",
    },
    Bangladesh: {
      mapSrc: `https://maps.google.com/maps?width=100%&height=600&hl=en&q=${encodeURIComponent(
        "220 guptapara ,rangpur Bangladesh"
      )}&ie=UTF8&t=&z=14&iwloc=B&output=embed`,
      address: "220 guptapara ,rangpur Bangladesh",
      phone: "+44 7769 475900",
      email: "annu@malaktel.com",
    },
    India: {
      mapSrc: `https://maps.google.com/maps?width=100%&height=600&hl=en&q=${encodeURIComponent(
        "Delhi"
      )}&ie=UTF8&t=&z=14&iwloc=B&output=embed`,
      address: "Delhi,India",
      phone: "+919599343465",
      email: "ravi@malaktel.com",
    },
    Russia: {
      mapSrc: `https://maps.google.com/maps?width=100%&height=600&hl=en&q=${encodeURIComponent(
        "Moscow ,Russia"
      )}&ie=UTF8&t=&z=14&iwloc=B&output=embed`,
      address: "Moscow ,Russia",
      phone: "+79 06065 77 06",
      email: "murad@malaktel.com",
    },
  };
  // Destructure the data for the selected country
  const { mapSrc, address, phone, email } = countryData[selectedCountry];

  return (
    <div name="Contact us">
      <section className="text-gray-600 body-font relative">
        <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <iframe
              width="100%"
              height="100%"
              className="absolute inset-0 filter grayscale-100 contrast-120 opacity-40"
              title="map"
              src={mapSrc}
            ></iframe>
            <div className="bg-white relative flex flex-wrap py-6 px-6 rounded shadow-md">
              <div className="lg:w-1/2 px-6">
                {" "}
                {/* Left Section */}
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p className="mt-1">{address}</p>
              </div>
              <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                {" "}
                {/* Right Section */}
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <a
                  className="text-indigo-500 leading-relaxed"
                  href={`mailto:${email}`}
                >
                  {email}
                </a>
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  PHONE
                </h2>
                <p className="leading-relaxed">{phone}</p>
              </div>
            </div>
          </div>
          <form
            action="https://formsubmit.co/support@malaktel-official.com"
            method="POST"
            className="lg:w-1/3 md:w-1/2 bg-gray-50 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0"
          >
            <label
              htmlFor="country"
              className="leading-7 mb-2 text-lg font-medium title-font text-gray-900"
            >
              Select Country
            </label>
            <select
              id="country"
              name="country"
              className="w-40 lg:w-full mb-3 bg-white rounded border border-gray-300 focus:border-gray-500 focus:ring-2 focus:ring-gray-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={handleCountryChange}
              value={selectedCountry}
            >
              <option value="Turky">Turky</option>
              <option value="Iraq">Iraq</option>
              <option value="Egypt">Egypt</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="India">India</option>
              <option value="Russia">Russia</option>
            </select>
            <input type="hidden" name="country" value={selectedCountry} />
            <input type="hidden" name="_captcha" value="false"></input>
            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
              Contact Us
            </h2>
            <div className="relative mb-4">
              <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                Name
              </label>
              <input
                required
                type="text"
                id="name"
                name="name"
                className="w-full bg-white rounded border border-gray-300 focus:border-gray-500 focus:ring-2 focus:ring-gray-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-600"
              >
                Email
              </label>
              <input
                required
                type="email"
                id="email"
                name="email"
                className="w-full bg-white rounded border border-gray-300 focus:border-gray-500 focus:ring-2 focus:ring-gray-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="message"
                className="leading-7 text-sm text-gray-600"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="w-full bg-white rounded border border-gray-300 focus:border-gray-500 focus:ring-2 focus:ring-gray-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>
            <button
              type="submit"
              className="text-white  bg-gray-900 border-0 py-2 px-6 focus:outline-none hover:bg-gray-800 rounded font-semibold text-lg"
            >
              Submit
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Contact_us;
