import React from "react";

export const Product = () => {
  return (
    <div
      name="Product"
      className="px-4 mt-28 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
    >
      <div className="pb-14">
        <p className="text-4xl text-gray-700 font-bold inline border-b-4 border-gray-500">
          Products
        </p>
      </div>
      <div
        className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full"
        data-aos="zoom-in-down"
        data-aos-duration="500"
      >
        <div className="overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl bg-white ">
          <img
            src="https://img1.wsimg.com/isteam/stock/xVyraOq/:/rs=h:1000,cg:true,m?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500"
            className="object-cover w-full h-64"
            alt="TelecomImg"
          />
          <div className="p-5 border border-t-0">
            <p className="mb-3 text-sm font-semibold tracking-wide uppercase">
              <a
                className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                aria-label="Category"
                title="Telecom"
              >
                Telecom
              </a>
            </p>
            <a
              aria-label="Category"
              title=" About Telecom Business"
              className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              About Telecom Business
            </a>
            <p className="mb-2 text-gray-700">
              Global <strong>VoIP</strong> and <strong>SMS</strong> provider
              serving wholesale and retail markets in multiple countries with
              comprehensive telecom solutions.
            </p>
            <a
              href="https://malaktel.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Learn more
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
        <div className="overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl bg-white">
          <img
            src="https://cosmic-s3.imgix.net/ed60b990-cb42-11e9-a833-4fe99c887177-selling_a_house_at_auction_vs_for_sale_by_owner_vs_a_traditional_agent-ogid-124804.jpg?auto=format&w=1920&q=20?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
            className="object-cover w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <p className="mb-3 text-sm font-semibold tracking-wide uppercase">
              <a
                className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                aria-label="Category"
                title="Auction"
              >
                Auction
              </a>
            </p>
            <a
              aria-label="Category"
              title="About Auction Business"
              className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              About Auction Business
            </a>
            <p className="mb-2 ml-2 text-gray-700">
              Auction platform offering worldwide <strong>buying</strong> and{" "}
              <strong>selling</strong> opportunities for unique products. List
              and acquire extraordinary items effortlessly.
            </p>
            <a
              href="https://auction-malaktel.vercel.app/"
              target="_blank"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Learn more
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
        <div className="overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl bg-white ">
          <img
            src="https://img.freepik.com/free-vector/security-concept-illustration-people-holding-chain_53876-43028.jpg?w=700&t=st=1684136114~exp=1684136714~hmac=a3c8fe6453526dbd85644a069c8e285d03d25f241bfb65efa896bc83a39b6342?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
            className="object-cover w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
              <a
                className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                aria-label="Category"
                title="traveling"
              >
                blockchain
              </a>
            </p>
            <a
              aria-label="Category"
              title="Film It!"
              className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              About Blockchain news
            </a>
            <p className="mt-2 text-gray-700">
              Discover a trusted platform offering comprehensive insights into
              the world of cryptocurrencies, including{" "}
              <strong>up-to-date news</strong>,{" "}
              <strong>live price tracking.</strong>
            </p>
            <a
              href="https://malaktel-news.com/"
              target="_blank"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800 mt-1"
            >
              Learn more
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Product;
