import React, { useState } from "react";
import { Link } from "react-scroll";

export const Navbar = () => {
  // State for menu open/close
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Array of links
  const links = [
    {
      id: 1,
      link: "Home",
    },
    {
      id: 2,
      link: "Product",
    },
    {
      id: 3,
      link: "Our Team",
    },
    {
      id: 4,
      link: "Contact us",
    },
    {
      id: 5,
      link: "About us",
    },
  ];
  return (
    <div
      name="Home"
      className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-full md:px-24 lg:px-8"
    >
      <div className="relative flex items-center justify-between">
        <a
          href="/"
          aria-label="Malaktel"
          title="Malaktel"
          className="inline-flex items-center"
        >
          <span className="ml-2 text-3xl font-bold tracking-wide text-black">
            Malak<span className=" text-gray-500">Tel</span>
          </span>
        </a>
        <ul className="flex items-end hidden space-x-5 lg:flex">
          {links.map(({ id, link }) => (
            <li
              key={id}
              className="px-4 cursor-pointer capitalize font-medium text-black hover:scale-105 duration-200"
            >
              <Link to={link} smooth duration={700}>
                {link}
              </Link>
            </li>
          ))}
        </ul>
        <div className="lg:hidden z-50">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            className="p-2 mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg className="w-5 text-black" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>
          {isMenuOpen && (
            <div className="absolute top-0 left-0 w-full">
              <div className="p-5 bg-white border rounded shadow-xl">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <a
                      href="/"
                      aria-label="Malaktel"
                      title="Malaktel"
                      className="inline-flex items-center"
                    >
                      <span className="ml-2 text-3xl font-bold tracking-wide text-gray-800">
                        Malak<span className="text-gray-500">Tel</span>
                      </span>
                    </a>
                  </div>
                  <div>
                    <button
                      aria-label="Close Menu"
                      title="Close Menu"
                      className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <nav>
                  <ul className="space-y-4">
                    {links.map(({ id, link }) => (
                      <li
                        key={id}
                        className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                      >
                        <Link to={link} smooth duration={700}>
                          {link}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
