import React from "react";

export const Footer = () => {
  return (
    <div name="About us">
      <div className="bg-gray-900">
        <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="grid row-gap-10 mb-8 lg:grid-cols-6">
            <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
              <div>
                <p className="font-medium tracking-wide text-gray-300">
                  Category
                </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <a
                      href="https://malaktel.com/"
                      target="_blank"
                      className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                    >
                      Telecom
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://tmkamal.github.io/under-construction-template/"
                      target="_blank"
                      className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                    >
                      Blockchain
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://tmkamal.github.io/under-construction-template/"
                      target="_blank"
                      className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                    >
                      Auction
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <span className="text-base font-bold tracking-wide text-gray-300">
                  Social
                </span>
                <div className="flex items-center mt-2 space-x-3">
                  <a
                    href="https://www.linkedin.com/company/malak-telecom-limited/"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-brand-linkedin"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />{" "}
                      <rect x="4" y="4" width="16" height="16" rx="2" />{" "}
                      <line x1="8" y1="11" x2="8" y2="16" />{" "}
                      <line x1="8" y1="8" x2="8" y2="8.01" />{" "}
                      <line x1="12" y1="16" x2="12" y2="11" />{" "}
                      <path d="M16 16v-3a2 2 0 0 0 -4 0" />{" "}
                    </svg>
                  </a>
                  <a
                    href="https://www.facebook.com/groups/781173790243997/"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
                    target="_blank"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5"
                    >
                      <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                    </svg>
                  </a>
                </div>
                <p className="mt-4 text-sm text-gray-500">
                  our social media accounts.
                </p>
              </div>
            </div>
            <div className="md:max-w-md lg:col-span-2 mt-3">
              <span className="text-base font-medium tracking-wide text-gray-300">
                Subscribe for updates
              </span>
              <form
                action="https://formsubmit.co/support@malaktel-official.com"
                method="POST"
                className="flex flex-col mt-4 md:flex-row"
              >
                <input type="hidden" name="_captcha" value="false"></input>
                <input
                  placeholder="Email"
                  required
                  type="email"
                  id="email"
                  name="email"
                  className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                />
                <input
                  type="hidden"
                  name="Email"
                  value="This email Subscribed Us"
                />
                <button
                  type="submit"
                  className="inline-flex bg-white items-center justify-center h-12 px-6 font-medium tracking-wide text-black hover:bg-gray-300 transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                >
                  Subscribe
                </button>
              </form>
              <p className="mt-4 text-sm text-gray-500">
                Subscribe us for updates.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center pt-5 pb-10 border-t border-gray-800 sm:flex-row">
            <p className="text-sm text-gray-500">
              © Copyright 2023 Malaktel -All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
