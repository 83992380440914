import Navbar from "./Component/Navbar";
import Home from "./Component/Home";
import Product from "./Component/Product";
import Contact from "./Component/Contact_us";
import Team from "./Component/Team";
import Footer from "./Component/Footer";

function App() {
  return (
    <div className="bg-gray-50">
      <Navbar />
      <Home />
      <Product />
      {/* <Team /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
